import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/seo';

import PageScreen from '../screens/page';
import { oneMatch, Product, stripHtml } from '../utils';
import usePreviewData from '../utils/usePreviewData';
import { trackProductList } from '../utils/gtm';
import { useLocale } from '../services/i18n/useLocale';

const PageTemplate = ({ data: rawData, pageContext }) => {
  const data = usePreviewData(rawData);
  const intl = useLocale();

  const page = data?.prismicPage?.data;

  const pageTitle = page.title?.text;

  const moreArticles = data?.more?.nodes || [];
  const products = (data?.products?.nodes || []).map((p) =>
    Product(p, intl.locale)
  );
  const displayedProducts = [];

  const content = (page?.body || []).map((slice) => {
    if (slice.slice_type === 'carousel') {
      return {
        ...slice,
        items: (slice.items || []).map((i) => i.image).filter((i) => i),
      };
    }

    if (slice.slice_type === 'produits') {
      return {
        ...slice,
        items: (slice.items || [])
          .map((i) => {
            const current = products.find((p) =>
              oneMatch(
                p.skus,
                i.product?.variants?.map((v) => v.sku)
              )
            );
            if (!current) {
              return null;
            }
            const result = {
              ...current,
              trackList: 'Page',
              trackPosition: displayedProducts.length + 1,
            };

            displayedProducts.push(result);
            return result;
          })
          .filter((p) => p),
      };
    }

    return slice;
  });

  trackProductList(
    displayedProducts.map((p) => ({
      sku: p.defaultVariant?.sku,
      name: p.title,
      price: p.defaultVariant?.price,
      variant: p.defaultVariant?.title,
      category: p.productType,
      list: p.trackList,
      position: p.trackPosition,
    }))
  );

  const firstText = (page?.body || []).find((s) => s.slice_type === 'contenu');
  const firstTextHtml = firstText?.primary?.content?.html;

  console.log('products', products);
  console.log('content', content);

  return (
    <>
      <Seo
        title={pageTitle}
        description={stripHtml(firstTextHtml || '')}
        image={page?.cover?.url}
      />
      <PageScreen
        page={{
          ...page,
          title: pageTitle,
          body: content,
        }}
        share={!!content.share}
        articles={moreArticles}
      />
    </>
  );
};

export const query = graphql`
  query (
    $prismic: ID!
    $locale: String!
    # $cmsLocale: String!
    $cmsLocaleGlob: String!
    $articlesLimit: Int
    $fetchProducts: [String!]
  ) {
    prismicPage(prismicId: { eq: $prismic }) {
      data {
        date
        title {
          text
        }
        centermode
        fullwidth
        share
        body {
          ... on PrismicPageDataBodyProduits {
            id
            primary {
              section_title
            }
            items {
              product {
                id
              }
            }
            slice_type
          }
          ... on PrismicPageDataBodyPromo {
            id
            slice_type
            primary {
              link {
                url
                target
              }
              imageMobile: image_mobile {
                alt
                dimensions {
                  height
                  width
                }
                url
              }
              image {
                dimensions {
                  height
                  width
                }
                url
                alt
              }
            }
          }
          ... on PrismicPageDataBodyCarousel {
            id
            primary {
              display_grid
              section_title
            }
            slice_type
            slice_label
            items {
              image {
                alt
                url
                dimensions {
                  height
                  width
                }
              }
            }
          }
          ... on PrismicPageDataBodyContenu {
            id
            slice_type
            slice_label
            primary {
              content {
                html
                richText: raw
              }
            }
          }
          ... on PrismicPageDataBodyFaq {
            id
            slice_type
            items {
              section_title
              content {
                html
              }
            }
          }
          ... on PrismicPageDataBodyIframe {
            id
            slice_type
            primary {
              url {
                url
              }
              height
            }
          }
        }
        cover {
          alt
          url
          dimensions {
            height
            width
          }
        }
        cover_mobile {
          alt
          url
          dimensions {
            height
            width
          }
        }
      }
    }
    more: allPrismicArticles(
      limit: $articlesLimit
      filter: { lang: { glob: $cmsLocaleGlob }, prismicId: { nin: [$prismic] } }
    ) {
      nodes {
        date: first_publication_date
        url
        data {
          title
          thumbnail {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
    products: allShopifyProduct(
      filter: { skus: { in: $fetchProducts }, locale: { eq: $locale } }
    ) {
      nodes {
        shopifyAdminId
        ...ProductItem
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        options {
          name
          values
        }
      }
    }
  }
`;
export default PageTemplate;
